import { HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import { httpRequestWrapper } from 'util/Request';
import RecaptchaResponse from 'store/types/Recaptcha';

class RecaptchaService {
  public static verify(siteKey: string, token: string, action: string): Promise<RecaptchaResponse> {
    return new Promise<RecaptchaResponse>(
      (resolve: (data: RecaptchaResponse) => void, reject: (error: string) => void) => {
        httpRequestWrapper<RecaptchaResponse>(
          HttpService.post(`${API_URL}/recaptcha/verify`, {
            headers: {
              TenantId: tenantConfig.id,
            },
            data: {
              siteKey,
              token,
              action,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default RecaptchaService;
