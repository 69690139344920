import React, { useContext } from 'react';
import { GoogleReCaptchaProvider } from '@google-recaptcha/react';
import { ConfigContext } from 'components/ConfigGuard';
import { tenantConfig } from 'config';

const RecaptchaProvider: React.FunctionComponent = ({ children }) => {
  const {
    modulesSettings: {
      payments: { enableRecaptcha },
    },
  } = useContext(ConfigContext);

  return enableRecaptcha ? (
    <GoogleReCaptchaProvider
      type="v2-checkbox"
      siteKey={tenantConfig.recaptchaSiteKey}
      isEnterprise
      host="recaptcha.net"
    >
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
};

export default RecaptchaProvider;
