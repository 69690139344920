import { Button, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, Grid, Link } from '@mui/material';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import PaymentMethodSection from 'components/payments/PaymentMethodSection';
import Card from 'components/shared/Card';
import Spinner from 'components/shared/Spinner';
import { UserContext } from 'components/UserGuard';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { GoogleReCaptchaCheckbox } from '@google-recaptcha/react';
import RecaptchaService from 'services/api/RecaptchaService';
import PaymentService, { DonationPaymentData } from 'services/api/PaymentService';
import { CONFIRM_EMAIL_FIELD_NAME, EMAIL_FIELD_NAME } from 'store/configs/FormFieldNames';
import routes from 'store/configs/Routes';
import CollectJSResponse from 'store/types/CollectJSResponse';
import DonationFund, { DonationCampaign, DonationHeaderValues } from 'store/types/DonationFund';
import { DonationInfoFormValues, PaymentFormValues, UserFormValues } from 'store/types/FormValues';
import {
  defaultFormProps,
  getAddressFormValues,
  getDonationsFormValues,
  getGiftDonationFormValues,
  getRequiredValidationRule,
  getValidationError,
} from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { defaultPaymentFormValues, getPaymentData, getPrice } from 'util/Payment';
import { getHashRouteUrl } from 'util/Route';
import { emptyUserFormValues } from 'util/User';
import SiteModule from 'store/enums/SiteModule';
import { PaymentMethodsContext } from 'components/PaymentMethodsContextWrapper';
import DonationHeader from './DonationHeader';
import DonationDetailsSection from './DonationDetailsSection';
import DonationPriceSection from './DonationPriceSection';
import DonationTermsModal from './DonationTermsModal';
import DonationUserLoginSection from './DonationUserLoginSection';
import DonationUserProfileSection from './DonationUserProfileSection';
import { tenantConfig } from 'config';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import Payment from 'store/types/Payment';
import { HttpError, HttpUtils } from 'services/HttpService';
import RequestErrorCode from 'store/enums/RequestErrorCode';
import DonationWarningModal from './DonationWarningModal';
import DonationRecurringPeriod from 'store/enums/DonationRecurringPeriod';
import DonationType from 'store/enums/DonationType';
import { ArrowBack } from '@mui/icons-material';
import TenantConfig from 'store/types/TenantConfig';
import Alert from '@mui/material/Alert';
import { SiteConfigView } from 'store/types/SiteConfig';
import SocialShareButtons from 'components/shared/SocialShareButtons';
import CreateCampaignModal from '../CreateCampaignModal';
import { isCampaignParent } from 'util/Donations';

import styles from './DonationPaymentPageView.module.scss';

interface DonationPaymentPageViewProps {
  donationFund: DonationFund;
  data: DonationCampaign[];
}

const defaultProfileFormValues: UserFormValues = {
  ...emptyUserFormValues,
  address: getAddressFormValues({ country: 'US' }),
  [CONFIRM_EMAIL_FIELD_NAME]: '',
};

const defaultDonationFormValues: DonationInfoFormValues = {
  ...getDonationsFormValues(),
  ...getGiftDonationFormValues(),
  payProcessingFee: true,
};

const getDonationPaymentData = (
  donationFormValues: DonationInfoFormValues,
  profileFormValues: UserFormValues,
  paymentForm: PaymentFormValues,
  donationId: string,
  processingFeeAmount: number,
  collectJSResponse?: CollectJSResponse,
  donationRegistrationUrl?: string,
  donationCampaignName?: string
): DonationPaymentData => {
  const { firstName, lastName, address, email }: UserFormValues = profileFormValues;
  const paymentFormValues: PaymentFormValues = {
    ...paymentForm,
    billingAddress: getAddressFormValues(address),
    useHomeAddress: false,
  };
  const donationValues: DonationInfoFormValues = getDonationsFormValues(donationFormValues);
  const { amount: amountWithoutFee }: DonationInfoFormValues = donationValues;
  const { totalPrice: amount }: PaymentFormValues = paymentFormValues;

  return {
    ...getPaymentData(paymentFormValues, collectJSResponse),
    ...donationValues,
    ...getGiftDonationFormValues(donationFormValues),
    amount,
    amountWithoutFee,
    firstName,
    lastName,
    email,
    donationId,
    donationRegistrationUrl,
    processingFeeAmount,
    donationCampaignName,
    donationCompanyLink: window.location.href,
  };
};

//TODO: code should be refactored and simplified
const DonationPaymentPageView: React.FunctionComponent<DonationPaymentPageViewProps> = ({ donationFund, data }) => {
  const {
    enabledModules,
    modulesSettings: {
      donations: { defaultFeePercent, isParentCampaignAllowed },
      payments: { donations, enableRecaptcha },
    },
    isGamsdTheme,
    isCompassTheme,
  }: SiteConfigView = useContext(ConfigContext);
  const { donationRegistrationUrl = '', recaptchaSiteKey }: TenantConfig = tenantConfig;
  const currentUser = useContext(UserContext);
  const { refetch: refetchPaymentMethods } = useContext(PaymentMethodsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { donationId = '' } = useParams<{ donationId: string }>();
  const profileForm = useForm<UserFormValues>({
    ...defaultFormProps,
    defaultValues: defaultProfileFormValues,
  });
  const donationForm = useForm<DonationInfoFormValues>({
    ...defaultFormProps,
    defaultValues: defaultDonationFormValues,
    shouldUnregister: false,
  });
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    useHomeAddress: false,
  });
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [campaignWarningModalOpen, setCampaignWarningModalOpen] = useState<boolean>(false);
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');
  const [displayValidationError, setDisplayValidationError] = useState<boolean>(false);
  const [payment, setPayment] = useState<Payment | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayPaymentMethodsError, setDisplayPaymentMethodsError] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');
  const [recaptchaExpired, setRecaptchaExpired] = useState<boolean>(true);
  const headerPreviewValues: DonationHeaderValues = useMemo(
    () => ({ ...donationFund.campaign, ...donationFund.donation }),
    [donationFund.campaign, donationFund.donation]
  );
  const {
    trigger: triggerProfile,
    reset: resetProfile,
    control: controlProfile,
    formState: { isValid: profileFormIsValid },
  } = profileForm;
  const {
    control,
    formState: { errors, isValid: donationFormIsValid },
    reset,
    getValues,
    trigger: triggerDonation,
    setError,
    clearErrors,
  } = donationForm;
  const { processingFeePercent = defaultFeePercent || 0, campaign }: DonationFund = donationFund;
  const acceptTermsError: string = errors['acceptTerms']?.message || '';
  const donationCampaignName: string = useMemo(() => (campaign ? campaign.title : ''), [campaign]);
  const [amount = 0, payProcessingFee, recurrentPeriod, donationType] = useWatch({
    control,
    name: ['amount', 'payProcessingFee', 'recurrentPeriod', 'donationType'],
  });
  const hasAuthorizedUser: boolean = useMemo(() => !!currentUser.id, [currentUser]);
  const saveMethodCheckboxDisabled: boolean = useMemo(
    () => !!recurrentPeriod && recurrentPeriod !== DonationRecurringPeriod.None,
    [recurrentPeriod]
  );
  const saveMethodCheckboxHidden: boolean = useMemo(
    () => !hasAuthorizedUser && donationType === DonationType.Single,
    [hasAuthorizedUser, donationType]
  );
  const formIsValid: boolean = useMemo(
    () =>
      profileFormIsValid &&
      donationFormIsValid &&
      !!paymentForm.totalPrice &&
      paymentForm.paymentMethodValid &&
      recurrentPeriod !== DonationRecurringPeriod.None,
    [recurrentPeriod, paymentForm, donationFormIsValid, profileFormIsValid]
  );
  const hasLoginSection = useMemo(() => enabledModules.includes(SiteModule.DonationsPremium), [enabledModules]);
  const processingFeeAmount: number = useMemo(
    () => (payProcessingFee && processingFeePercent ? Number(((amount / 100) * processingFeePercent).toFixed(2)) : 0),
    [processingFeePercent, amount, payProcessingFee]
  );
  const emailValue: string = useWatch({ control: controlProfile, name: EMAIL_FIELD_NAME });

  useEffect(() => {
    if (hasAuthorizedUser) {
      resetProfile({
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        middleInitial: currentUser.middleInitial || '',
        phone: currentUser.phone || '',
        email: currentUser.email || '',
        address: getAddressFormValues(currentUser.address),
      });
    }
  }, [resetProfile, hasAuthorizedUser, currentUser]);

  useEffect(() => {
    if (donationType === DonationType.Single) {
      clearErrors('recurrentPeriod');
      triggerDonation('recurrentPeriod');
    }
  }, [clearErrors, donationType, triggerDonation]);

  useEffect(() => {
    if (recurrentPeriod && recurrentPeriod !== DonationRecurringPeriod.None) {
      clearErrors('recurrentPeriod');

      if (!paymentForm.saveMethod) {
        setPaymentForm((prevState) => ({
          ...prevState,
          saveMethod: true,
        }));
      }
    }
  }, [recurrentPeriod, paymentForm, clearErrors, triggerDonation]);

  useEffect(() => {
    if (paymentForm.paymentMethodId) {
      setDisplayPaymentMethodsError(false);
    }
  }, [paymentForm]);

  useEffect(() => {
    if (formIsValid) {
      setDisplayValidationError(false);
    }
  }, [formIsValid]);

  useEffect(() => {
    setPaymentForm((prevState) => ({
      ...prevState,
      totalPrice: payProcessingFee ? amount + processingFeeAmount : amount,
    }));
  }, [payProcessingFee, processingFeePercent, amount, processingFeeAmount]);

  useEffect(() => {
    if (loginError) {
      setLoginError('');
    }
  }, [emailValue, loginError]);

  const handlePaymentInfoChange = useCallback((values) => {
    setPaymentForm((prevState) => ({ ...prevState, ...values }));
  }, []);

  const handleTermsLinkClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    setTermsModalOpen(true);
  }, []);

  const handleTermsModalClose = useCallback(() => {
    setTermsModalOpen(false);
  }, []);

  const handleWarningModalClose = useCallback(() => {
    setWarningModalOpen(false);
    setCampaignWarningModalOpen(false);
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!enableRecaptcha) {
      return true;
    }

    if (!recaptchaToken || recaptchaExpired) {
      enqueueSnackbar('reCAPTCHA expired. Please complete reCAPTCHA and try again.', defaultSnackbarErrorProps);
      return false;
    }

    const response = await RecaptchaService.verify(recaptchaSiteKey, recaptchaToken, 'donation_payment');
    return response.success;
  }, [enableRecaptcha, enqueueSnackbar, recaptchaExpired, recaptchaSiteKey, recaptchaToken]);

  const makePayment = useCallback(
    (collectJSResponse?: CollectJSResponse) => {
      const data: DonationPaymentData = getDonationPaymentData(
        donationForm.getValues(),
        profileForm.getValues(),
        paymentForm,
        donationId,
        processingFeeAmount,
        collectJSResponse,
        donationRegistrationUrl,
        donationCampaignName
      );

      PaymentService.makeDonationPayment(data, !hasAuthorizedUser)
        .then((payment: Payment) => {
          if (hasAuthorizedUser) {
            refetchPaymentMethods();
          }
          setLoading(false);
          enqueueSnackbar('Payment successfully confirmed', { variant: 'success' });
          setPayment(payment);
        })
        .catch((error: HttpError) => {
          const errorMessage: string = HttpUtils.getErrorMessage(error);
          const errorCode: string = HttpUtils.getErrorCode(error);

          if (errorCode === RequestErrorCode.EmailAlreadyRegistered && !hasAuthorizedUser) {
            setWarningModalOpen(true);
          } else {
            enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          }
          setLoading(false);
        });
    },
    [
      enqueueSnackbar,
      donationForm,
      profileForm,
      paymentForm,
      donationId,
      hasAuthorizedUser,
      refetchPaymentMethods,
      donationRegistrationUrl,
      processingFeeAmount,
      donationCampaignName,
    ]
  );

  const handlePaymentComplete = (response: CollectJSResponse) => {
    if (formIsValid) {
      makePayment(response);
    }
  };

  const handlePaymentButtonClick = useCallback(() => {
    setPayment(undefined);
    push(routes.home);
  }, [push]);

  const handlePaymentModalClose = useCallback(() => {
    const { donationType } = getValues();
    setPayment(undefined);
    setPaymentForm({
      ...defaultPaymentFormValues,
      useHomeAddress: false,
    });
    reset({ ...defaultPaymentFormValues, ...defaultDonationFormValues, donationType });
    (window as any).CollectJS.clearInputs();
  }, [reset, getValues]);

  const handleDonationFormSubmit = useCallback(() => {
    setLoading(true);
    if (paymentForm.paymentMethodId) {
      makePayment();
    } else {
      (window as any).CollectJS.startPaymentRequest();
    }
  }, [paymentForm, makePayment]);

  const handleFormSubmit = useCallback(() => {
    if (formIsValid) {
      window.scrollTo(0, 0);
      handleReCaptchaVerify()
        .then((success) => {
          if (success) {
            handleDonationFormSubmit();
          } else {
            enqueueSnackbar('reCAPTCHA verification failed. Please try again later.', defaultSnackbarErrorProps);
          }
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    } else {
      triggerDonation();
      triggerProfile();
      setDisplayValidationError(true);
      window.scrollTo(0, 0);
      (window as any).CollectJS.startPaymentRequest();
      if (!paymentForm.paymentMethodId) {
        setDisplayPaymentMethodsError(true);
      }
      if (recurrentPeriod === DonationRecurringPeriod.None) {
        setError('recurrentPeriod', { message: 'Please select frequency', type: 'required' });
      }
    }
  }, [
    formIsValid,
    handleReCaptchaVerify,
    handleDonationFormSubmit,
    enqueueSnackbar,
    triggerDonation,
    triggerProfile,
    paymentForm.paymentMethodId,
    recurrentPeriod,
    setError,
  ]);

  const handleReturnHomeButtonClick = useCallback(() => {
    if (isCompassTheme) {
      push(routes.donations);
    } else if (isGamsdTheme) {
      window.open('https://www.medicalservicedogs.org/', '_blank');
    }
  }, [isCompassTheme, isGamsdTheme, push]);

  const handleChange = useCallback((onChange) => (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), []);

  const handleCampaignModalClose = useCallback(() => {
    setCreateCampaignModalOpen(false);
  }, []);

  const handleCreateCampaignClick = useCallback(() => {
    hasAuthorizedUser ? setCreateCampaignModalOpen(true) : setCampaignWarningModalOpen(true);
  }, [hasAuthorizedUser]);

  const handleRecaptchaChange = useCallback((token: string) => {
    if (token) {
      setRecaptchaToken(token);
      setRecaptchaExpired(false);
    }
  }, []);

  const handleRecaptchaExpire = useCallback(() => {
    setRecaptchaExpired(true);
  }, []);

  const handleRecaptchaError = useCallback(() => {
    enqueueSnackbar('reCAPTCHA failed to load. Please refresh the page.', defaultSnackbarErrorProps);
  }, [enqueueSnackbar]);

  useEffect(() => {
    console.log(recaptchaToken);
  }, [recaptchaToken]);

  return (
    <>
      <Spinner
        loading={loading}
        className={styles.spinner}
        label={'Please wait. Your payment is currently being processed. Do not refresh or close your browser.'}
      >
        <DonationHeader data={headerPreviewValues} />
        <Container maxWidth={'xl'} className={styles.sectionContent}>
          <Link href={getHashRouteUrl(routes.donations)} className={styles.backLink} color={'textPrimary'}>
            <ArrowBack className={styles.backIcon} />
            {'Return Back to the Donation List'}
          </Link>
          {isCampaignParent(donationFund.campaign) &&
            !isParentCampaignAllowed &&
            enabledModules.includes(SiteModule.DonationsPremium) &&
            !isCompassTheme && (
              <div className={styles.createCampaignButton}>
                <h3>{'Want to help support this cause?'}</h3>
                <Button color={'secondary'} variant={'contained'} onClick={handleCreateCampaignClick}>
                  {'Create a Campaign'}
                </Button>
              </div>
            )}
        </Container>
        <section>
          <Container maxWidth={'xl'}>
            <div className={styles.socialButtons}>
              <SocialShareButtons shareLink={window.location.href} size={'large'} />
            </div>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps}>
                {displayValidationError && (
                  <Alert severity={'error'}>
                    {'Your payment cannot be completed. Please enter all required fields.'}
                  </Alert>
                )}
              </Grid>
              <Grid {...defaultGridItemProps} lg={6}>
                <Grid {...defaultGridContainerProps}>
                  {hasLoginSection && (
                    <Grid {...defaultGridItemProps}>
                      <FormProvider {...profileForm}>
                        <DonationUserLoginSection donationId={donationId} />
                      </FormProvider>
                    </Grid>
                  )}
                  <Grid {...defaultGridItemProps}>
                    <FormProvider {...donationForm}>
                      <DonationPriceSection donationFund={donationFund} />
                    </FormProvider>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <FormProvider {...donationForm}>
                      <DonationDetailsSection />
                    </FormProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid {...defaultGridItemProps} lg={6}>
                <Grid {...defaultGridContainerProps}>
                  <Grid {...defaultGridItemProps}>
                    <FormProvider {...profileForm}>
                      <DonationUserProfileSection loginError={loginError} />
                    </FormProvider>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <Card
                      contentClassName={styles.cardContent}
                      headerClassName={styles.cardHeader}
                      title={<h2>Payment Information</h2>}
                    >
                      <PaymentMethodSection
                        showRequiredError={displayPaymentMethodsError}
                        form={paymentForm}
                        onChange={handlePaymentInfoChange}
                        onPaymentComplete={handlePaymentComplete}
                        saveMethodCheckboxDisabled={saveMethodCheckboxDisabled}
                        saveMethodCheckboxVisible={!saveMethodCheckboxHidden}
                        paymentConfig={donations}
                      />
                      <div className={styles.summaryRow}>
                        <span>{`Donation Amount: ${getPrice(amount)}`}</span>
                      </div>
                      <div className={styles.summaryRow}>
                        <span>{`Transaction Fee: ${getPrice(processingFeeAmount)}`}</span>
                      </div>
                      <div className={styles.summaryRow}>
                        <span>{`Total Donation: ${getPrice(paymentForm.totalPrice)}`}</span>
                      </div>
                    </Card>
                  </Grid>
                  <Grid {...defaultGridItemProps}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                            )}
                            control={control}
                            name={'subscribeToUpdates'}
                          />
                        }
                        label={'Yes, I wish to receive occasional emails about the organization. (Optional)'}
                      />
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <Checkbox color={'primary'} onChange={handleChange(onChange)} checked={value} />
                            )}
                            control={control}
                            name={'isAnonymousDonation'}
                          />
                        }
                        label={'Make the donation anonymous for public view. (Optional)'}
                      />
                    </FormControl>
                    <FormControl error={getValidationError('acceptTerms', errors)} required={true}>
                      <FormControlLabel
                        control={
                          <Controller
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Checkbox color={'secondary'} onChange={handleChange(onChange)} checked={value} />
                              </>
                            )}
                            control={control}
                            name={'acceptTerms'}
                            rules={{ required: getRequiredValidationRule('terms and conditions', true) }}
                          />
                        }
                        classes={{ label: styles.termsAndConditionsLabel }}
                        label={
                          <>
                            {'By completing the donation, I agree to '}
                            <Link className={styles.termsAndConditionsLink} onClick={handleTermsLinkClick}>
                              {'terms and conditions'}
                            </Link>
                            {'. (Required)'}
                          </>
                        }
                      />
                      <FormHelperText>{acceptTermsError}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classNames(styles.darkSection, styles.footer)}>
          <Container maxWidth={'xl'} className={styles.sectionContent}>
            <Grid {...defaultGridContainerProps}>
              {enableRecaptcha && (
                <Grid {...defaultGridItemProps}>
                  <div className={styles.formFooter}>
                    <GoogleReCaptchaCheckbox
                      action={'donation_payment'}
                      onChange={handleRecaptchaChange}
                      expiredCallback={handleRecaptchaExpire}
                      errorCallback={handleRecaptchaError}
                    />
                  </div>
                </Grid>
              )}
              <Grid {...defaultGridItemProps}>
                <div className={styles.formFooter}>
                  <Button
                    color={'secondary'}
                    variant={'contained'}
                    onClick={handleFormSubmit}
                    disabled={enableRecaptcha && (!recaptchaToken || recaptchaExpired)}
                  >
                    {'Complete Payment'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </Spinner>
      <DonationTermsModal open={termsModalOpen} onClose={handleTermsModalClose} />
      <CreateCampaignModal
        open={createCampaignModalOpen}
        onClose={handleCampaignModalClose}
        donations={data}
        defaultParentCampaignValue={campaign.id}
      />
      <DonationWarningModal
        donationId={donationId}
        onClose={handleWarningModalClose}
        open={warningModalOpen || campaignWarningModalOpen}
        description={
          warningModalOpen
            ? 'This email has already created an account. Log in to complete your donation.'
            : 'Sign In to create your personal donation campaign and view your donation history. If this is your first time logging in, create an account to complete your donation campaign setup.'
        }
        buttonName={warningModalOpen ? 'Sign In' : 'Sign or Create Account'}
      />
      {!!payment && (
        <PaymentDetailsModal
          open={!!payment}
          onClose={handlePaymentModalClose}
          payment={payment}
          actions={
            (isGamsdTheme || isCompassTheme) && (
              <div className={styles.actionButtons}>
                {hasAuthorizedUser && (
                  <Button color={'secondary'} variant={'contained'} onClick={handlePaymentButtonClick}>
                    {'Visit Manage Donations Portal'}
                  </Button>
                )}
                <Button color={'secondary'} variant={'contained'} onClick={handleReturnHomeButtonClick}>
                  {'Return Home'}
                </Button>
              </div>
            )
          }
        />
      )}
    </>
  );
};
export default DonationPaymentPageView;
